import React from "react";
import {Switch, Route} from "react-router-dom";
import "./Admin.css";
import Sidebar from "../components/Sidebar/Sidebar.js";
import paths from "../constants/paths";
import CreateAccount from "../pages/account/create";
import Accounts from "../pages/account";
import useRoutes from "../routes";
import EditAccount from "../pages/account/edit";

function Admin() {
  const routes = useRoutes();

  const [sidebarBackground] = React.useState("black");
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            key={key}
            component={prop.component}
            exact
          />
        );
      } else {
        return null;
      }
    });
  };
  return (
    <>
      <div className="wrapper">
        <Sidebar
          routes={routes}
          background={sidebarBackground}
        />
        <div className="main-panel">
          <div className="admin_content">
            <Switch>
              {getRoutes(routes)}
              <Route exact path={paths.CREATE_ACCOUNT} render={(props) => <CreateAccount {...props} />}/>
              <Route exact path={paths.LIST_ACCOUNT} render={(props) => <Accounts {...props} />}/>
              <Route exact path={paths.EDIT_ACCOUNT} render={(props) => <EditAccount {...props} />}/>

            </Switch>
          </div>
          <div
            className="close-layer"
            onClick={() =>
              document.documentElement.classList.toggle("nav-open")
            }
          />
        </div>
      </div>
    </>
  );
}

export default Admin;
