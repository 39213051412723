import Papa from "papaparse";

const useCsvDownloader = () => {
  const downloadCsv = async (data, filename) => {
    console.log(filename);
    const aTag = document.createElement("a");
    data = "\uFEFF" + Papa.unparse(data);
    const blob = new Blob([data], { type: "text/csv;charset=utf-8" });
    const url = URL.createObjectURL(blob);
    aTag.href = url;
    aTag.download = filename;
    document.body.appendChild(aTag);
    aTag.click();
    aTag.remove();
  };

  return { downloadCsv };
};

export default useCsvDownloader;
