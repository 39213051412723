import {APP_TYPE, APP_TYPES} from "./constants/app.js";
import WebTable from "./pages/web/WebTable.js";
import FAQTable from "./pages/faq/FAQTable.js";
import FilePage from "./pages/file";
import ChatboxSettingForm from "./pages/tag/ChatboxSettingForm.js";
import ResourcePage from "./pages/resource";
import {useSelector} from "react-redux";
import Account from "./pages/account";
import DashboardPage from "./pages/dashboard";
import CategoryPage from "./pages/category";
import DepartmentMessagePage from "pages/department_chat/index.js";

const useRoutes = () => {
  const groups = useSelector(state => state.users?.userInfo.groups);
  const isStaffGroup = groups && (groups.includes('insideAdminStaff') || groups.includes('outsideAdminStaff') || groups.includes('staff'));
  const { userInfo } = useSelector((state) => state.users)
  let routes = [
    {
      path: "/",
      layout: "/admin",
      name: "ダッシュボード",
      icon: "nc-icon nc-chart-bar-32",
      component: DashboardPage,
    },
    {
      path: "/qa_list_new",
      layout: "/admin",
      name: "QAリスト",
      icon: "nc-icon nc-bullet-list-67",
      component: DepartmentMessagePage,
    },
    APP_TYPE !== APP_TYPES.ADMIN && {
      collapse: true,
      path: "/data_management",
      name: "データ管理",
      state: "openDataManagement",
      icon: "nc-icon nc-cloud-upload-94",
      views: [
        {
          path: "/website",
          layout: "/admin",
          name: "WEBサイト",
          component: WebTable,
        },
        {
          path: "/file",
          layout: "/admin",
          name: "ファイル",
          component: FilePage,
        },
        {
          path: "/qalist",
          layout: "/admin",
          name: "FAQ",
          component: FAQTable,
        },
      ],
    },
    !isStaffGroup && {
      path: "/data_reflection",
      layout: "/admin",
      name: "データ反映",
      icon: "nc-icon nc-refresh-02",
      component: ResourcePage,
    },
    !isStaffGroup && {
      path: "/department_tag",
      layout: "/admin",
      name: "部署タグ登録",
      icon: "nc-icon nc-tag-content",
      component: CategoryPage,
    },
    !isStaffGroup && {
      path: "/chat_tag",
      layout: "/admin",
      name: "Webチャット設定",
      icon: "nc-icon nc-settings-gear-64",
      component: ChatboxSettingForm,
    },
    !isStaffGroup && {
      path: `/governments/${userInfo.governmentId}/accounts`,
      layout: "/admin",
      name: "アカウント",
      icon: "nc-icon nc-single-02",
      component: Account,
    },
  ].filter((x) => x);

  return routes;
};

export default useRoutes;
