import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import "./CSVDownloadDialog.css";

const propTypes={
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onDownload: PropTypes.func
};
const defaultProps={
  isOpen: false,
  onClose: () => {},
  onDownload: ()=>{}
};
const CSVDownloadDialog = (props) => {
  /*** States and Variables ***/
  const { isOpen, onClose, onDownload } = props;
  const [filterValues, setFilterValues] = useState({
    month: "",
    rating: "",
  });
  const [error, setError] = useState("");

  const handleChange = (event) => {
    setFilterValues({
      ...filterValues,
      [event.target.name]: event.target.value,
    });
    setError("");
  };

  const handleDownload = () => {
    if (!filterValues.month) {
      setError("期間を選択してください。");
      return;
    }
    onDownload(filterValues);
  };

  /*** Processing ***/
  /*** Sub Components ***/
  /*** Event Handlers ***/
  /*** Main Render ***/
  if (!isOpen) return null;
  return <div
    className="modal show"
    style={{ display: 'block', position: 'absolute' }}>
    <Modal.Dialog className="p-0">
      <Modal.Header closeButton onHide={onClose}>
        <Modal.Title>ダウンロードする期間を選択してください。</Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          maxHeight: '256px',
          overflow: 'auto'
        }}>
        <input
          value={filterValues.month}
          type="month"
          name="month"
          onChange={handleChange}
          className='csv_download_dialog_input' />
        {/*
        <select
          name="rating"
          className="form-control csv_download_dialog_input"
          value={filterValues.rating}
          width={70}
          onChange={handleChange}
        >
          <option value="">評価を選択</option>
          <option value="良い">良い</option>
          <option value="悪い">悪い</option>
          <option value="評価なし">評価なし</option>
        </select>
        */}
        {error && <div className="text-danger mt-2">{error}</div>}
      </Modal.Body>

      <Modal.Footer className="d-flex justify-content-end">
        <Button
          className="btn-outline btn-wd mr-1"
          variant="default"
          onClick={onClose}>
          キャンセル
        </Button>
        <Button
          className="btn-outline btn-wd mr-1"
          variant="primary"
          onClick={handleDownload}>
          CSVダウンロード
        </Button>
      </Modal.Footer>
    </Modal.Dialog>
  </div>
}
CSVDownloadDialog.propTypes = propTypes;
CSVDownloadDialog.defaultProps = defaultProps;
export default CSVDownloadDialog;
