import React from "react";
import { Button, Modal, Container, Form } from 'react-bootstrap';
import { Label } from "reactstrap";
import PropTypes from "prop-types";
import { BUTTON_LABELS, WEEKDAY_LABELS } from "../lang/ja";
import moment from "moment";
import { EXECUTION_TYPES } from "../constants/status";
import { WEEKDAYS } from "../constants/resource";

const propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  isCreateDialog: PropTypes.bool,
  editable:  PropTypes.bool,
  enableRecurring:  PropTypes.bool,
  onSubmit: PropTypes.func,
  initExecutionType: PropTypes.oneOf(Object.values(EXECUTION_TYPES)),
  initExecutionDetail: PropTypes.object
};
const defaultProps = {
  isOpen: false,
  onClose: ()=>{},
  isCreateDialog: null,
  editable: true,
  enableRecurring: false,
  onSubmit: () => {},
  initExecutionType: EXECUTION_TYPES.IMMEDIATE,
  initExecutionDetail: {executionInterval: 'Weekly'}
};

const BatchModalDialog = (props) => {
  const { isOpen, onClose, onSubmit, editable, enableRecurring, initExecutionType, initExecutionDetail } = props;
  const currentDateTime = new Date();
  const [ executionType, setExecutionType ] = React.useState(EXECUTION_TYPES.IMMEDIATE);
  const [ executionDetail, setExecutionDetail ] = React.useState({
    scheduledDate: currentDateTime,
  });
  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const hours = Array.from({ length: 24 }, (_, index) => index);

  React.useEffect(()=>{
    setExecutionType(initExecutionType)
  }, [initExecutionType])
  React.useEffect(()=>{
    if(initExecutionDetail){
      setExecutionDetail(initExecutionDetail);
    }

  }, [initExecutionDetail])


  const handleSubmit = () => {
    executionDetail.executionInterval = 'Weekly'
    const isTime = executionDetail?.scheduledDate instanceof Date && !isNaN(executionDetail.scheduledDate.getTime())
    if(executionType===EXECUTION_TYPES.SCHEDULED){
      if(!executionDetail.scheduledDate){
        executionDetail.scheduledDate = currentDateTime
      }
      if (!isTime){
        const timeEx=new Date(executionDetail?.scheduledDate)
        executionDetail.scheduledDate=timeEx
      }
      if(executionDetail?.scheduledDate <= currentDateTime){
        const oneHourLater = new Date(currentDateTime.getTime() + 60 * 60 * 1000);
        executionDetail.scheduledDate = oneHourLater;
      }
      executionDetail.scheduledDate.setMinutes(0);
    }
    if(executionType===EXECUTION_TYPES.RECURRING){
      if(!executionDetail?.executionDay||!executionDetail?.startDate||!executionDetail?.executionTime){
        const dayOfWeek = currentDateTime.getDay();
        const justNow = currentDateTime.getHours();
        executionDetail.executionDay= executionDetail?.executionDay ? (executionDetail.executionDay):(daysOfWeek[dayOfWeek])
        executionDetail.startDate= executionDetail?.startDate ? (executionDetail.startDate) : (moment(currentDateTime).format('YYYY-MM-DD'))
        executionDetail.executionTime= executionDetail?.executionTime ? (executionDetail.executionTime) : (justNow >= 10 ? `${justNow}:00` : `0${justNow}:00`)
      }
    }
    onSubmit(executionType, executionDetail);

  };
  const onExecutionDetailChange = (newProps) => {
    let props={}
    const date = moment(executionDetail?.scheduledDate).format('YYYY-MM-DD')||moment(currentDateTime).format('YYYY-MM-DD');
    const hour = moment(executionDetail?.scheduledDate).format('HH')||currentDateTime.getTime();
    let updatedDetails = {...executionDetail};
    if(newProps?.executionTime){
      const hour = newProps?.executionTime || ""
      const hourFormatted = parseInt(hour) >= 10 ? `${hour}` : `0${hour}`
      newProps.executionTime =`${hourFormatted}:00`
    }
    if(newProps?.executionDate || newProps?.executionHour){
      const exDate = newProps?.executionDate || date;
      const exHour = newProps?.executionHour || hour;
      let hourFormatted = exHour
      if(newProps?.executionHour){
        hourFormatted = parseInt(exHour) >= 10 ? `${exHour}` : `0${exHour}`;
      }
      updatedDetails.scheduledDate = new Date(`${exDate}T${hourFormatted}:00:00`);
      newProps = updatedDetails
    }
    props = newProps
    setExecutionDetail(Object.assign({}, executionDetail, props));
  }
  /***** Sub Components *****/
  const renderImmediateSetting = () => {
    return <div>
      <input
        type="radio"
        checked={executionType===EXECUTION_TYPES.IMMEDIATE}
        onChange={()=>setExecutionType(EXECUTION_TYPES.IMMEDIATE)} />
      <label onClick={()=>setExecutionType(EXECUTION_TYPES.IMMEDIATE)} >
        即時反映
      </label>
    </div>
  }

  const renderScheduleSetting = () => {
    return <>
      <div>
        <input
          type="radio"
          checked={executionType===EXECUTION_TYPES.SCHEDULED}
          onChange={()=>setExecutionType(EXECUTION_TYPES.SCHEDULED)} />
        <label onClick={()=>setExecutionType(EXECUTION_TYPES.SCHEDULED)}>
          日時指定
        </label>
      </div>
      {executionType === EXECUTION_TYPES.SCHEDULED && (
        <>
          <input
            type='date'
            onChange={(e) => onExecutionDetailChange({executionDate: e.target.value})}
            defaultValue={moment(executionDetail?.scheduledDate).add(9, 'hours') >= currentDateTime ? moment(executionDetail?.scheduledDate).format('YYYY-MM-DD') : moment(currentDateTime).format('YYYY-MM-DD')}
            min={moment(currentDateTime).format('YYYY-MM-DD') }
            disabled={executionType !== EXECUTION_TYPES.SCHEDULED}
          />
          <select
            style={{padding:"8px"}}
            id="hourSelect"
            value={parseInt(moment(executionDetail?.scheduledDate).format('HH'))}
            onChange={(e) => onExecutionDetailChange({executionHour: e.target.value})}>
            {hours.map((hour) => (
              <option key={hour} value={hour}>
                {hour < 10 ? `0${hour}:00` : `${hour}:00`}
              </option>
            ))}
          </select>
        </>
      )}

    </>
  }
  const renderRecurringSetting = () => {
    if (!enableRecurring) return null;
    return <>
      <div>
        <input
          type="radio"
          checked={executionType===EXECUTION_TYPES.RECURRING}
          onChange={()=>setExecutionType(EXECUTION_TYPES.RECURRING)} />
        <label onClick={()=>setExecutionType(EXECUTION_TYPES.RECURRING)}>
          定期実行
        </label>
      </div>
      <div>
        {executionType===EXECUTION_TYPES.RECURRING &&
        <Form className="cycle-form " onSubmit={handleSubmit}>
          <Container  style={{
            paddingBottom: '8px',
          }}
          className="d-flex align-items-center">
            <br />
            <Label className="w-25 m-20">間隔</Label>
            <Label  className="w-75">
              <input
                type="number"
                value="Weekly"
                min={1}
                disabled
              />
              <select
                value="Weekly"
              >
                <option value="Weekly">毎週</option>
              </select>
            </Label>
          </Container>

          <Container style={{
            paddingBottom: '8px'
          }}
          className="d-flex align-items-center">
            <Label className="w-30 m-0">
            曜日
            </Label>
            <Label className="w-75 ">
              {Object.values(WEEKDAYS).map((day) => {
                return <label key={day} className="circular-checkbox">
                  <input
                    type="checkbox"
                    checked={executionDetail?.executionDay===day}
                    onChange={() => {onExecutionDetailChange({executionDay: day})}}
                  />
                  <span className="checkmark">{WEEKDAY_LABELS[day]}</span>
                </label>
              })}
            </Label>
          </Container>
          <Container style={{
            paddingBottom: '8px'
          }}
          className="d-flex align-items-center">
            <Label className="w-30 m-0">
              開始日
            </Label>
            <input
              type='date'
              value={executionDetail?.startDate}
              min={moment(currentDateTime).format('YYYY-MM-DD')}
              onChange={(e)=>{onExecutionDetailChange({startDate: e.target.value})}} />
          </Container>
          <Container style={{
            paddingBottom: '8px'
          }}
          className="d-flex align-items-center">
            <Label className="w-30 m-0">
              実行時間
            </Label>
            <select
              style={{padding:"8px"}}
              id="hourSelect"
              value={parseInt((executionDetail?.executionTime || "0:00").split(":")[0])}
              onChange={(e)=>{onExecutionDetailChange({executionTime: e.target.value})}}>
              {hours.map((hour) => (
                <option key={hour} value={hour}>
                  {hour < 10 ? `0${hour}:00` : `${hour}:00`}
                </option>
              ))}
            </select>
          </Container>
        </Form>
        }
      </div>
    </>
  }
  /***** Main Render *****/
  if (!isOpen) return null;
  return <div
    className="modal show"
    style={{ display: 'block', position: 'absolute',top:"15%" }}>
    <Modal.Dialog className="p-0">
      <Modal.Body
        style={{
          maxHeight: '256px',
          overflow: 'auto'
        }}>
        <h5>データの反映日時を設定してください。</h5>
        {renderImmediateSetting()}
        {renderScheduleSetting()}
        {renderRecurringSetting()}
      </Modal.Body>

      <Modal.Footer className="d-flex justify-content-end">
        <Button
          className="btn-outline btn-wd mr-1"
          variant="default"
          onClick={onClose}>
          キャンセル
        </Button>
        <Button
          className="btn-outline btn-wd mr-1"
          variant="default"
          disabled={!editable}
          onClick={()=>{handleSubmit()}}>
          {BUTTON_LABELS.BUTTON_REGISTER}
        </Button>
      </Modal.Footer>
    </Modal.Dialog>
  </div>
}

BatchModalDialog.propTypes = propTypes;
BatchModalDialog.defaultProps = defaultProps;

export default BatchModalDialog;
