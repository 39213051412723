import { getGovernment } from 'apis/GovernmentApi';
import React, { useState, useContext, createContext } from 'react';
import { useSelector } from 'react-redux';
const propTypes={};
const defaultProps = {};

const Context = createContext({});
const GovernmentProvider = (props) => {
  /*** States and Variables ***/
  const [government, setGovernment] = useState(null);
  const {userInfo} = useSelector((state) => state.users);
  /*** Processing ***/
  React.useEffect(() => {
    const downloadGovernment = async () => {
      // eslint-disable-next-line no-console
      if (userInfo?.governmentId) {
        const res = await getGovernment(userInfo.governmentId);
        if (res.success) {
          setGovernment(res.data);
        }
      }
    };
    downloadGovernment();
   }, [userInfo])
  /*** Sub Components ***/
  /*** Event Handlers ***/
  /*** Main Render ***/
  return <Context.Provider value={{ government, setGovernment }}>
    {/* eslint-disable-next-line react/prop-types */}
    {props.children}
  </Context.Provider>;
}
GovernmentProvider.propTypes = propTypes;
GovernmentProvider.defaultProps = defaultProps;
export default GovernmentProvider;

export const useGovernment = () => useContext(Context);
