import React, { useState } from "react";
import PropTypes from "prop-types";
import { Card, Col, Table, Badge, Button, DropdownButton, Dropdown } from "react-bootstrap";
import PaginationComponent from "../../components/PaginationComponent";
import { NUM_ITEMS_PER_PAGE } from "constants/table";
import moment from "moment";
import CSVDownloadDialog from "../../pages/qa/CSVDownloadDialog";
import { BUTTON_LABELS } from "../../lang/ja";
import { useGovernment } from "contexts/GovernmentContext";
import { getCredibilityColor } from "../../utils/resources";

const propTypes = {
  messages: PropTypes.array,
  nextToken: PropTypes.string,
  onDownloadMessages: PropTypes.func,
  onDownloadCsv: PropTypes.func,
  onClickMessage: PropTypes.func,
};
const defaultProps = {
  messages: [],
  nextToken: null,
  onDownloadMessages: () => {},
  onDownloadCsv: () => {},
  onClickMessage: () => {},
};
const DepartmentConversationTable = (props) => {
  const { messages, nextToken, onDownloadMessages, onDownloadCsv, onClickMessage } = props;
  const [curPage, setCurPage] = useState(0);
  const [csvDownloadDialogOpen, setCsvDownloadDialogOpen] = useState(false);
  const { government } = useGovernment();
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("部署タグ選択");
  const [selectedLikeFilter, setSelectedLikeFilter] = useState("評価を選択");
  console.log(government);

  const onPageChange = (page) => {
    if (page + 2 > Math.ceil(messages.length / NUM_ITEMS_PER_PAGE)) {
      onDownloadMessages();
    }
    setCurPage(page);
  };

  const getDepartmentName = (department) => {
    const pos = department.indexOf("#");
    return pos >= 0 ? department.substring(0, pos) : department;
  };

  const handleDepartmentSelect = (department) => {
    setSelectedDepartment(department);
  };

  const handleLikeFilterSelect = (likeFilter) => {
    setSelectedLikeFilter(likeFilter);
  };

  const handleSearch = () => {
    onDownloadMessages(selectedDepartment, selectedLikeFilter, searchKeyword);
  };

  const handleReset = () => {
    setSearchKeyword("");
    handleDepartmentSelect("部署タグ選択");
    handleLikeFilterSelect("評価を選択");
    onDownloadMessages("部署タグ選択", "評価を選択");
  };

  const getLikeFilterTitle = (filter) => {
    if (filter === "評価を選択") return "評価を選択";
    if (filter > 0) return "良い評価";
    if (filter < 0) return "悪い評価";
    return "評価なし";
  };


  return (
    <>
      <Col md="12">
        <Card className="strpied-tabled-with-hover">
          <Card.Header>
            <div className="mb-3">
              <Card.Title as="h4">QAリスト</Card.Title>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center gap-2">
                {" "}
                <input
                  type="text"
                  className="form-control"
                  placeholder="質問や回答文、タグ名を入力"
                  value={searchKeyword}
                  onChange={(e) => setSearchKeyword(e.target.value)}
                  style={{ width: "300px", marginRight: "8px" }}
                />
                <DropdownButton variant="secondary" title={selectedDepartment} style={{ marginRight: "16px" }}>
                  <Dropdown.Item key="all" onClick={() => setSelectedDepartment("部署タグ選択")}>
                    部署タグ選択
                  </Dropdown.Item>
                  {(government?.departments || []).map((department) => {
                    const departmentName = getDepartmentName(department);
                    return (
                      <Dropdown.Item key={departmentName} onClick={() => handleDepartmentSelect(departmentName)}>
                        {departmentName}
                      </Dropdown.Item>
                    );
                  })}
                </DropdownButton>
                <DropdownButton
                  variant="secondary"
                  title={getLikeFilterTitle(selectedLikeFilter)}
                  style={{ marginRight: "8px" }}
                >
                  <Dropdown.Item onClick={() => handleLikeFilterSelect("評価を選択")}>評価を選択</Dropdown.Item>
                  <Dropdown.Item onClick={() => handleLikeFilterSelect(1)}>良い評価</Dropdown.Item>
                  <Dropdown.Item onClick={() => handleLikeFilterSelect(-1)}>悪い評価</Dropdown.Item>
                  <Dropdown.Item onClick={() => handleLikeFilterSelect(null)}>評価なし</Dropdown.Item>
                </DropdownButton>
                <Button variant="primary" onClick={handleSearch} style={{ marginRight: "8px" }}>
                  検索する
                </Button>
                <Button
                  variant="link"
                  onClick={() => {
                    handleReset();
                  }}
                  className="text-primary p-0"
                  style={{ textDecoration: "none" }}
                >
                  検索リセット
                </Button>
              </div>
            </div>
            <div className="d-flex flex-row justify-content-between align-items-end" style={{ paddingTop: 4 }}>
              <PaginationComponent
                currentPage={curPage}
                totalItems={messages.length}
                onPageChange={onPageChange}
                nextPageIndex={nextToken}
              />
              <Button
                className="btn-outline mr-1"
                variant="primary"
                onClick={() => {
                  setCsvDownloadDialogOpen(true);
                }}
              >
                {BUTTON_LABELS.BUTTON_DOWNLOAD}
              </Button>
            </div>
          </Card.Header>
          <Card.Body className="table-responsive p-0 overflow-auto">
            <Table className="table-hover w-full">
              <thead>
                <tr>
                  <th>質問</th>
                  <th>部署タグ</th>
                  <th>信頼度</th>
                  <th>開始日</th>
                  <th>評価</th>
                </tr>
              </thead>
              <tbody>
                {messages.map((message, i) => {
                  if (i < curPage * NUM_ITEMS_PER_PAGE || i >= (curPage + 1) * NUM_ITEMS_PER_PAGE) return null;
                  return (
                    <tr
                      key={message.conversationId + message.createdAt}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        onClickMessage(message);
                      }}
                    >
                      <td>{message.question}</td>
                      <td>
                        {message.tag !== "undefined" && message.tag ? (
                          <Badge
                            style={{
                              backgroundColor: "#007bff",
                              color: "#ffffff",
                              padding: "0.4em 0.8em",
                              fontSize: "0.8rem",
                              borderRadius: "16px",
                              fontWeight: "normal",
                              display: "inline-block",
                              cursor: "pointer",
                            }}
                          >
                            {message.tag}
                          </Badge>
                        ) : (
                          <span className="text-muted">未分類</span>
                        )}
                      </td>
                      <td>
                        {message.credibility !== undefined && message.credibility && (
                          <div
                            style={{
                              color: getCredibilityColor(message.credibility),
                              fontWeight: "bold",
                            }}
                          >
                            {message.credibility}
                          </div>
                        )}
                      </td>
                      <td>{moment(message.createdAt).format("YYYY/MM/DD HH:mm")}</td>
                      <td>
                        {message.like ? (
                          <Badge
                            variant={message.like > 0 ? "primary" : "danger"}
                            style={{
                              padding: "0.4em 0.5em",
                              fontSize: "0.8rem",
                            }}
                          >
                            {message.like > 0 ? "良い" : "悪い"}
                          </Badge>
                        ) : (
                          <Badge
                            variant="secondary"
                            style={{
                              padding: "0.4em 0.5em",
                              fontSize: "0.8rem",
                            }}
                          >
                            評価なし
                          </Badge>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Col>
      <CSVDownloadDialog
        isOpen={csvDownloadDialogOpen}
        onClose={() => {
          setCsvDownloadDialogOpen(false);
        }}
        onDownload={(filterValues) => {
          onDownloadCsv(filterValues);
          setCsvDownloadDialogOpen(false);
        }}
      />
    </>
  );
};
DepartmentConversationTable.propTypes = propTypes;
DepartmentConversationTable.defaultProps = defaultProps;
export default DepartmentConversationTable;
