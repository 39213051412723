import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import DepartmentConversationTable from "./DepartmentConversationTable";
import { listExternalMessagesByGovernment, listMessagesByMonth2 } from "../../apis/MessageApi";
import FanLoadingIcon from "components/FanLoadingIcon";
import { MESSAGES } from "lang/ja";
import { useDialog } from "contexts/DialogContext";
import moment from "moment";
import useCsvDownloader from "../../components/Hooks/useCsvDownloader";
import UpdateAnswerDialog from "pages/qa/UpdateAnswerDialog";
import { createFaq, getExternalResource, updateResource } from "../../apis/ResourceApi";
import { updateMessage } from "../../apis/MessageApi";

const propTypes = {};
const defaultProps = {};
const DepartmentConversationPage = () => {
  /*** States and Variables ***/
  const { userInfo } = useSelector((state) => state.users);
  const governmentId = userInfo?.governmentId;
  const messagesRef = useRef([]);
  const [messages, setMessages] = useState(messagesRef.current);
  const nextTokenRef = useRef(null);
  const [nextToken, setNextToken] = useState(nextTokenRef.current);
  const loadingRef = useRef(false);
  const [loading, setLoading] = useState(false);
  const { error } = useDialog();
  const { downloadCsv } = useCsvDownloader();
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedLikeFilter, setSelectedLikeFilter] = useState(null);
  const [faq, setFaq] = useState(null);


  const downloadMessages = async (department, likeFilter, keyword) => {
    if (loadingRef.current) return;
    loadingRef.current = true;
    setLoading(loadingRef.current);

    const searchOptions = {
      tag: department,
      like: likeFilter,
      keyword: keyword,
    };

    const res = await listExternalMessagesByGovernment(governmentId, nextTokenRef.current, searchOptions);
    if (res.success) {
      messagesRef.current = [...messagesRef.current, ...res.data];
      setMessages(messagesRef.current);
      nextTokenRef.current = res.nextToken;
      setNextToken(nextTokenRef.current);
    }

    loadingRef.current = false;
    setLoading(loadingRef.current);
  };

  const onDownloadMessages = async (department, likeFilter, keyword) => {
    if (department !== undefined || likeFilter !== undefined || keyword !== undefined) {
      messagesRef.current = [];
      nextTokenRef.current = null;
      setSelectedDepartment(department);
      setSelectedLikeFilter(likeFilter);
    }

    if (nextToken || department !== undefined || likeFilter !== undefined || keyword !== undefined) {
      await downloadMessages(department || selectedDepartment, likeFilter || selectedLikeFilter, keyword);
    }
  };

  useEffect(() => {
    const getGovernmentData = async () => {
      if (governmentId) {
        await downloadMessages();
      }
    };
    getGovernmentData();
  }, [governmentId]);

  const onDownloadCsv = async (filterValues) => {
    const { month } = filterValues;
    if (!month) return;
    if (loadingRef.current) return;
    loadingRef.current = true;
    setLoading(loadingRef.current);
    const res = await listMessagesByMonth2(governmentId, month);
    loadingRef.current = false;
    setLoading(loadingRef.current);
    if (res.success) {
      let data = [];
      for (const message of res.data) {
        const row = [
          message.conversationId,
          moment(message.createdAt).format("yyyy/MM/DD HH:mm"),
          message.question,
          message.answer + (message.url ? "\n" + message.url : ""),
          message.tag !== undefined ? message.tag : "",
          message.like > 0 ? "良い" : message.like < 0 ? "悪い" : "評価なし",
        ];
        data.push(row);
      }
      data = [["ID","日時", "質問", "回答", "タグ", "評価"], ...data];
      await downloadCsv(data, "qaリスト_" + month + ".csv");
    } else {
      error(MESSAGES.SOMETHING_WRONG);
    }
  };

  const onClickMessage = async (message) => {
    setSelectedMessage(message);
    if (message.fixAnswerCreatedAt) {
      const res = await getExternalResource(message.governmentId, message.fixAnswerCreatedAt);
      if (res.success) {
        setFaq(res.data);
      }
    }
  };

  const onCloseMessage = () => {
    setSelectedMessage(null);
    setFaq(null);
  };

  const onUpdateAnswer = async (newAnswer, newUrl, newTag) => {
    if (loadingRef.current) return;
    loadingRef.current = true;
    setLoading(loadingRef.current);
    // update faq
    let tempFaq;
    if (newAnswer !== selectedMessage?.answer || newUrl !== selectedMessage?.url) {
      if (faq) {
        const faqRes = await updateResource(faq, { answer: newAnswer, url: newUrl });
        tempFaq = faqRes.data;
      } else {
        const faqRes = await createFaq(
          selectedMessage.governmentId,
          selectedMessage.question,
          newAnswer,
          newUrl,
          JSON.stringify({
            conversationId: selectedMessage.conversationId,
            createdAt: selectedMessage.createdAt,
          }),
          true
        );
        tempFaq = faqRes.data;
      }
    }

    // update message
    if (newAnswer !== selectedMessage?.answer || newUrl !== selectedMessage?.url || newTag !== selectedMessage?.tag) {
      const msgRes = await updateMessage(selectedMessage.conversationId, selectedMessage.createdAt, {
        url: newUrl,
        tag: newTag,
        fixAnswerCreatedAt: tempFaq?.createdAt,
      });
      if (msgRes.success) {
        const newMessage = msgRes.data;
        const pos = messages.findIndex((msg) => msg.createdAt === newMessage.createdAt);
        if (pos >= 0) {
          messages[pos] = newMessage;
          setMessages(Object.assign([], messages));
        }
      }
    }
    loadingRef.current = false;
    setLoading(loadingRef.current);
    setSelectedMessage(null);
  };

  return (
    <>
      <DepartmentConversationTable
        messages={messages}
        nextToken={nextToken}
        onDownloadMessages={onDownloadMessages}
        onDownloadCsv={onDownloadCsv}
        onClickMessage={onClickMessage}
      />
      <UpdateAnswerDialog
        isOpen={!!selectedMessage}
        onClose={onCloseMessage}
        question={selectedMessage?.question}
        aiAnswer={selectedMessage?.answer + (selectedMessage?.url ? "\n" + selectedMessage?.url : "")}
        answer={faq?.answer}
        credibility={selectedMessage?.credibility}
        credibilityReason={selectedMessage?.credibilityReason}
        url={faq?.url?.trim()}
        tag={selectedMessage?.tag == "undefined" ? "" : selectedMessage?.tag}
        onUpdate={onUpdateAnswer}
      />
      {loading && <FanLoadingIcon size={80} />}
    </>
  );
};
DepartmentConversationPage.propTypes = propTypes;
DepartmentConversationPage.defaultProps = defaultProps;
export default DepartmentConversationPage;
