import React, { useState, useEffect, useRef } from "react";
import "./CategoryPage.css";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { updateGovernment, extractDepartments } from "../../apis/GovernmentApi";
import { Button } from "react-bootstrap";
import { useDialog } from "contexts/DialogContext";
import FanLoadingIcon from "components/FanLoadingIcon";
import { useGovernment } from "../../contexts/GovernmentContext";

const propTypes = {};
const defaultProps = {};
const CategoryPage = () => {
  const { handleSubmit } = useForm();
  const { userInfo } = useSelector((state) => state.users);
  const governmentId = userInfo?.governmentId;
  const { government, setGovernment } = useGovernment();
  const [newDepartment, setNewDepartment] = useState("");
  const [newDepartmentWork, setNewDepartmentWork] = useState("");
  const [departments, setDepartments] = useState([]);
  const formRef = useRef(null);
  const { success, error } = useDialog();
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState("");

  useEffect(() => {
    if (government && government.departments) {
      setDepartments(government.departments);
    }
  }, [government]);

  const onAddDepartment = () => {
    if (newDepartment) {
      setDepartments([...departments, `${newDepartment}#${newDepartmentWork}`]);
      setNewDepartment("");
      setNewDepartmentWork("");
    }
  };

  const parseDepartmentData = (data) => {
    try {
      if (!Array.isArray(data) || data.length === 0) return [];

      // 配列の各要素を処理
      const departments = data
        .map((item, index) => {
          // 最初の要素から "department": [ を削除
          if (index === 0) {
            return item.replace(/{\s*"department":\s*\[\s*"/, "");
          }
          // 最後の要素から ]} を削除
          if (index === data.length - 1) {
            return item.replace(/"\s*\]\s*}$/, "");
          }
          return item;
        })
        .map((item) => item.replace(/^"|"$/g, "")) // 各要素の前後のダブルクォートを削除
        .filter((item) => item.trim() !== ""); // 空の要素を削除

      return departments;
    } catch (error) {
      console.error("Failed to parse department data:", error);
      return [];
    }
  };

  const onDeleteDepartment = (index) => {
    const newDepartments = [...departments];
    newDepartments.splice(index, 1);
    setDepartments(newDepartments);
  };

  const renderCategories = () => {
    const departmentViews = departments.map((category, index) => {
      const pos = category.indexOf("#");
      const name = pos < 0 ? category : category.substring(0, pos);
      const work = pos < 0 ? "" : category.substring(pos + 1);
      return (
        <div key={`${index}`} className="category_page_department_container">
          <input
            className="category_page_department_input"
            value={name}
            onInput={(e) => onDepartmentChange(e.target.value, work, index)}
          />
          <input
            className="category_page_department_work"
            placeholder="部署の業務内容を入力"
            value={work}
            onInput={(e) => onDepartmentChange(name, e.target.value, index)}
          />
          <Button className="category_page_department_delete_button" onClick={() => onDeleteDepartment(index)}>
            削除
          </Button>
        </div>
      );
    });

    departmentViews.push(
      <>
        <div className="category_page_department_container">
          <input
            className="category_page_department_input"
            placeholder="新しい部署を追加"
            value={newDepartment}
            onInput={(e) => setNewDepartment(e.target.value)}
          />
          <input
            className="category_page_department_work"
            placeholder="部署の業務内容を入力"
            value={newDepartmentWork}
            onInput={(e) => setNewDepartmentWork(e.target.value)}
          />
        </div>
        <Button
          variant="outline-primary"
          className="category_page_add_button"
          onClick={onAddDepartment}
          disabled={!newDepartment}
        >
          タグを新たに追加
        </Button>
      </>
    );
    return departmentViews;
  };

  const onSubmit = async () => {
    let departmentsToSave = [...departments];
    if (newDepartment) {
      departmentsToSave.push(`${newDepartment}#${newDepartmentWork}`);
    }

    const res = await updateGovernment(governmentId, { departments: departmentsToSave });
    if (res.success) {
      success("保存しました");
      setGovernment(res.data);
      setNewDepartmentWork("");
      setNewDepartment("");
    } else {
      error("保存に失敗しました");
    }
  };

  const onDepartmentChange = (name, work, index) => {
    if (name === "") {
      departments[index] = `#${work}`;
      setDepartments([...departments]);
    } else {
      departments[index] = `${name}#${work}`;
      setDepartments([...departments]);
    }
  };

  const onListDepartments = async () => {
    if (loading) return;
    setLoading(true);
    const res = await extractDepartments(url);
    if (res.success) {
      const parsedDepartments = parseDepartmentData(res.data);
      const formattedDepartments = parsedDepartments.map((dept) => `${dept}#`);
      setDepartments(formattedDepartments);
    } else {
      error("部署の取得に失敗しました");
    }
    setLoading(false);
  };

  return (
    <form className="category_page_form_container" onSubmit={handleSubmit(onSubmit)} ref={formRef}>
      <label className="category_page_title">部署タグ</label>
      <div>部署一覧を記載したサイトページから、タグに登録したい部署名を抽出できます。</div>
      <div className="category_page_note">
        （※生成AIで抽出しているため、一部の部署名などに誤りが生じる可能性があります）
      </div>
      <div className="category_page_search_contianer">
        <input
          className="category_page_search_input"
          value={url}
          placeholder="部署の一覧を記載したページのurlを入力"
          onChange={(e) => setUrl(e.target.value)}
        />
        <Button className="category_page_search_button" variant="default" type="button" onClick={onListDepartments}>
          部署名を抽出
        </Button>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "16px" }}>
        <div>部署名・業務内容</div>
        {departments.length > 0 && (
          <Button className="category_page_department_delete_button" onClick={() => setDepartments([])}>
            一括削除
          </Button>
        )}
      </div>
      {renderCategories()}
      <div className="category_page_actions_contianer">
        <Button className="btn-primary btn-wd mr-1 category_page_button_save" variant="default" type="submit">
          保存
        </Button>
      </div>
      {loading && <FanLoadingIcon size={80} />}
    </form>
  );
};
CategoryPage.propTypes = propTypes;
CategoryPage.defaultProps = defaultProps;
export default CategoryPage;
