import React from 'react';
import { WEEKDAYS } from "constants/resource";
import { Badge } from "react-bootstrap";
import { RESOURCE_STATUS_LABELS } from "../lang/ja";
import moment from "moment";

// eslint-disable-next-line no-undef
const { RESOURCE_STATUS, EXECUTION_TYPES } = require("../constants/status");
// eslint-disable-next-line no-undef
const { PROCESS_TYPES } = require("../constants/status");

export const getStatusLabel = (resource) => {
  let text = RESOURCE_STATUS_LABELS[resource.status] || '未定義';
  let variant = 'secondary'; // デフォルトの色

  switch (resource.status) {
    case RESOURCE_STATUS.SCRAPING:
      variant = 'info';
      break;
    case RESOURCE_STATUS.SCRAPE_FINISHED:
      variant = 'success';
      break;
    case RESOURCE_STATUS.SCRAPE_FAILED:
      variant = 'danger';
      break;
    case RESOURCE_STATUS.S3_FINISHED:
      variant = 'success';
      break;
    case RESOURCE_STATUS.S3_FAILED:
      variant = 'danger';
      break;
    case RESOURCE_STATUS.PENDING:
      variant = 'warning';
      break;
    case RESOURCE_STATUS.PROCESS_REQUESTING:
    case RESOURCE_STATUS.REPROCESS_REQUESTING:
      variant = 'primary';
      break;
    case RESOURCE_STATUS.WAITING:
      variant = 'info';
      break;
    case RESOURCE_STATUS.PROCESSING:
      // PROCESS_TYPESを考慮して分岐
      variant = resource.process === PROCESS_TYPES.DELETE ? 'warning' : 'info';
      text = resource.process === PROCESS_TYPES.DELETE ? '削除中' : 'ロード中';
      break;
    case RESOURCE_STATUS.PROCESS_REJECTED:
      variant = 'danger';
      break;
    case RESOURCE_STATUS.FAILED:
      variant = 'danger';
      break;
    case RESOURCE_STATUS.FINISHED:
      variant = 'success';
      break;
    case RESOURCE_STATUS.DELETE_REQUESTING:
      variant = 'primary';
      break;
    case RESOURCE_STATUS.DELETE_REJECTED:
      variant = 'danger';
      break;
    case RESOURCE_STATUS.DELETING:
      variant = 'warning';
      break;
    case RESOURCE_STATUS.DELETED:
      variant = 'success';
      break;
    default:
      break;
  }

  return <Badge variant={variant} style={{ padding: '0.4em 0.5em', fontSize: '0.8rem' }}>{text}</Badge>;
};

const WEEKTIME = 7 * 24 * 3600000
export const getProcessTime = (resource) => {
  if (typeof resource.executionDetail === "string") {
    try {
      resource.executionDetail = JSON.parse(resource.executionDetail);
    } catch (e) {
      console.error("Invalid JSON in executionDetail:", resource.executionDetail);
      return null; // JSONが無効である場合は処理を中断
    }
  }

  // 以下の処理は有効な executionDetail のみで行う
  if (resource.execution === EXECUTION_TYPES.IMMEDIATE) {
    return null;

  } else if (resource.execution === EXECUTION_TYPES.SCHEDULED) {
    if (resource.executionDetail?.scheduledDate) {
      return moment(resource.executionDetail.scheduledDate).format("yyyy/MM/DD HH:mm");
    }
    return null;

  } else {
    const now = new Date();
    let dDaysValue = Object.values(WEEKDAYS).indexOf(resource.executionDetail?.executionDay) + 1;
    if (dDaysValue > 7) {
      dDaysValue = 0;
    }
    const startTime = new Date(resource.executionDetail?.startDate + " " + resource.executionDetail?.executionTime);
    let targetDay = new Date(startTime);
    let dDays = dDaysValue - startTime.getDay();
    if (dDays < 0) {
      dDays += 7;
    }
    targetDay.setDate(targetDay.getDate() + dDays);
    let dWeeks = Math.ceil((now.getTime() - targetDay.getTime()) / WEEKTIME);
    dWeeks = dWeeks < 0 ? 0 : dWeeks;
    let formattedDate = moment(new Date(targetDay.getTime() + dWeeks * WEEKTIME)).format("yyyy/MM/DD HH:mm");
    return formattedDate === "Invalid date" ? "" : formattedDate;
  }
};

export const getStatusKeyByLabel = (label) => {
  const statusKey = Object.keys(RESOURCE_STATUS_LABELS).find(key => RESOURCE_STATUS_LABELS[key] === label);
  return statusKey || null;
};

export const getCredibilityColor = (credibility) => {
  switch (credibility) {
    case "信頼できる":
      return "#28a745";
    case "やや信頼できる":
      return "#ffc107";
    case "信頼できない":
      return "#dc3545";
    default:
      return "#565656";
  }
};
