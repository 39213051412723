import React from "react";
import ReactDOM from "react-dom/client";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "assets/scss/light-bootstrap-dashboard-pro-react.scss?v=2.0.0";
import "assets/css/demo.css";

import config from './aws-exports';
import { Amplify } from 'aws-amplify';
import { Provider } from 'react-redux';
import store from './store';
import App from "./app";
import { BrowserRouter } from "react-router-dom";
import DialogProvider from "./contexts/DialogContext";
import GovernmentProvider from "./contexts/GovernmentContext";

Amplify.configure(config);
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <DialogProvider>
      <GovernmentProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </GovernmentProvider>
    </DialogProvider>
  </Provider>
);
