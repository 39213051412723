import React from "react";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Button, Container, Form, Modal } from "react-bootstrap";
import { useGovernment } from "contexts/GovernmentContext";
import { getCredibilityColor } from "../../utils/resources";

const propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onUpdate: PropTypes.func,
  question: PropTypes.string,
  answer: PropTypes.string,
  aiAnswer: PropTypes.string,
  credibility: PropTypes.string,
  credibilityReason: PropTypes.string,
  url: PropTypes.string,
  tag: PropTypes.string,
  editable: PropTypes.bool,
};
const defaultProps = {
  isOpen: false,
  onClose: () => {},
  onUpdate: () => {},
  question: "",
  answer: "",
  aiAnswer: "",
  credibility: "",
  credibilityReason: "",
  url: "",
  tag: "",
  editable: true,
};

const UpdateAnswerDialog = (props) => {
  const { isOpen, onClose, onUpdate, question, answer, aiAnswer, credibility, credibilityReason, url, tag, editable } =
    props;
  const [fixAnswer, setFixAnswer] = useState(answer);
  const [fixUrl, setFixUrl] = useState(url);
  const [fixTag, setFixTag] = useState(tag);
  const [validated, setValidated] = useState(false);
  const [exceededLimit, setExceededLimit] = useState(false);
  const { government } = useGovernment();
  useEffect(() => {
    setFixAnswer(answer);
  }, [answer]);

  useEffect(() => {
    setFixUrl(url);
  }, [url]);

  useEffect(() => {
    setFixTag(tag);
  }, [tag]);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() && !exceededLimit) {
      onUpdate(fixAnswer, fixUrl, fixTag);
      setFixAnswer("");
      setFixUrl("");
      onClose();
    }
    setValidated(true);
  };

  const onFixAnswerChange = (event) => {
    const inputValue = event.target.value;
    setFixAnswer(inputValue);
    setExceededLimit(inputValue.length > 2000);
    setValidated(event.target.form.checkValidity() && !exceededLimit);
  };

  const isButtonDisabled = () => {
    if (tag!==fixTag) return false;
    return !validated || !editable || exceededLimit;
  };

  if (!isOpen) return null;

  return (
    <Modal
      dialogClassName="custom-modal-position"
      show={isOpen}
      onHide={onClose}
      backdropClassName="modal show"
      size="lg"
    >
      <Form noValidate validated={validated} onSubmit={handleFormSubmit}>
        <Modal.Header closeButton onHide={onClose}>
          <Modal.Title className="">回答詳細</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "65vh", overflow: "auto" }}>
          <Container style={{ paddingBottom: "8px" }} className="d-flex align-items-center">
            <Form.Label className="w-25 m-0">ユーザーの質問</Form.Label>
            <Form.Control className="w-75" plaintext readOnly defaultValue={question} />
          </Container>
          <Container style={{ paddingBottom: "16px" }} className="d-flex align-items-center">
            <Form.Label className="w-25 m-0">AIの回答</Form.Label>
            <div
              className="w-75 form-control-plaintext"
              style={{
                whiteSpace: "pre-wrap",
                wordBreak: "break-word",
                minHeight: "150px",
                padding: "8px 12px",
                lineHeight: "1.5",
                backgroundColor: "#f8f9fa",
                border: "1px solid #e9ecef",
                borderRadius: "4px",
              }}
            >
              {aiAnswer}
            </div>
          </Container>
          <Container style={{ paddingBottom: "8px" }} className="d-flex align-items-center">
            <Form.Label className="w-25 m-0">回答の信頼度</Form.Label>
            <Form.Control
              className="w-75"
              plaintext
              readOnly
              defaultValue={credibility}
              style={{
                color: getCredibilityColor(credibility),
                fontWeight: "bold",
              }}
            />
          </Container>
          <Container style={{ paddingBottom: "24px" }} className="d-flex align-items-start">
            <Form.Label className="w-25 m-0">{credibility}理由</Form.Label>
            <div
              className="w-75 form-control-plaintext"
              style={{
                whiteSpace: "pre-wrap",
                wordBreak: "break-word",
                minHeight: "100px",
                padding: "8px 0",
                lineHeight: "1.5",
              }}
            >
              {credibilityReason}
            </div>
          </Container>
          <Container style={{ paddingBottom: "8px" }} className="d-flex align-items-center">
            <Form.Label className="w-25 m-0">回答を修正する</Form.Label>
            <div className="w-75">
              <Form.Control
                as="textarea"
                rows={3}
                value={fixAnswer}
                disabled={!editable}
                onChange={onFixAnswerChange}
                required
                isInvalid={exceededLimit}
              />
              <Form.Control.Feedback type="invalid">
                {exceededLimit && "回答は2000文字以下で入力してください。"}
              </Form.Control.Feedback>
            </div>
          </Container>
          <Container style={{ paddingBottom: "8px" }} className="d-flex align-items-center">
            <Form.Label className="w-25 m-0">URL</Form.Label>
            <Form.Control
              className="w-75"
              as="textarea"
              rows={1}
              value={fixUrl}
              disabled={!editable}
              onChange={(e) => setFixUrl(e.target.value)}
            />
          </Container>
          <Container style={{ paddingBottom: "8px" }} className="d-flex align-items-start">
            <Form.Label className="w-25 m-0">タグ</Form.Label>
            <div className="w-75 d-flex flex-wrap gap-2">
              {(government?.departments || []).map((department) => {
                const pos = department.indexOf("#");
                const departmentName = pos >= 0 ? department.substring(0, pos) : department;
                return (
                  <div key={departmentName} className="position-relative" style={{ minWidth: "fit-content" }}>
                    <Form.Check
                      type="radio"
                      id={`radio-${departmentName}`}
                      name="department-radio"
                      checked={fixTag === departmentName}
                      onChange={() => setFixTag(departmentName)}
                      className="d-none"
                    />
                    <label
                      htmlFor={`radio-${departmentName}`}
                      className="px-3 py-1 rounded-pill"
                      style={{
                        border: "1px solid #dee2e6",
                        backgroundColor: fixTag === departmentName ? "#007bff" : "#ffffff",
                        color: fixTag === departmentName ? "#ffffff" : "#6c757d",
                        cursor: "pointer",
                        transition: "all 0.2s ease",
                        fontSize: "0.9rem",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {departmentName}
                    </label>
                  </div>
                );
              })}
            </div>
          </Container>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-end">
          <Button className="btn-outline btn-wd mr-1" variant="default" onClick={onClose}>
            キャンセル
          </Button>
          <Button
            className="btn-outline btn-wd mr-1"
            variant="default"
            disabled={isButtonDisabled()}
            onClick={() => {
              onUpdate(fixAnswer, fixUrl, fixTag);
            }}
          >
            保存
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

UpdateAnswerDialog.propTypes = propTypes;
UpdateAnswerDialog.defaultProps = defaultProps;
export default UpdateAnswerDialog;
