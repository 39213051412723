import { API } from 'aws-amplify';
import {
  listInternalMessages,
  findExternalConversationsByCityId,
  findExternalConversationsSortByEvaluation,
  findExternalConversationsSortBySimilarity,
  listExternalMessages,
  getExternalMessage as getExternalMessageCmd,
  getInternalMessage as getInternalMessageCmd,
  findInternalConversationsByCityId,
  findExternalMessagesByGovernment
} from '../graphql/queries';
import {
  updateInternalMessage,
  updateExternalMessage,
  deleteInternalConversation,
  deleteExternalConversation,
  deleteInternalMessage,
  deleteExternalMessage
} from '../graphql/mutations';
import {
  createFaq,
  updateResource
} from './ResourceApi';
import { APP_AUTH_MODE } from '../constants/app';
import {
  customFindInternalConversationsByCityId,
  customFindInternalConversationsByEvaluation,
  customFindInternalConversationsBySimilarity
} from "../graphql/customQueries";
import { NUM_ITEMS_PER_PAGE } from 'constants/table';

const _getMessage = async (conversationId, msgCreatedAt, isExternal=true) => {
  try {
    const query = isExternal? getExternalMessageCmd: getInternalMessageCmd;
    const res = await API.graphql({
      query,
      variables: {
        conversationId,
        createdAt: msgCreatedAt
      },
      authMode: APP_AUTH_MODE
    })
    return {
      success: true,
      data: res.data[isExternal? 'getExternalMessage': 'getInternalMessage']
    }
  }
  catch (e) {
    return {
      success: false,
      error: e
    }
  }
}

const getExternalMessage = async (conversationId, msgCreatedAt) => {
  return await _getMessage(conversationId, msgCreatedAt, true);
}

const getInternalMessage = async (conversationId, msgCreatedAt) => {
  return await _getMessage(conversationId, msgCreatedAt, false);
}

const _listConversationsSortByDate = async (cityId, nextToken, sortDirection='DESC', isExternal=true) => {
  try {
    const res = await API.graphql({
      query: isExternal? findExternalConversationsByCityId: customFindInternalConversationsByCityId,
      variables: {
        cityId: cityId,
        sortDirection,
        nextToken
      },
      authMode: APP_AUTH_MODE
    })

    if (res.data[isExternal? 'findExternalConversationsByCityId': 'findInternalConversationsByCityId']) {
      const data = res.data[isExternal? 'findExternalConversationsByCityId': 'findInternalConversationsByCityId'];
      const items = data.items;
      for(const item of items) {
        if (item.evaluation) {
          item.evaluation = JSON.parse(item.evaluation);
        }
      }
      return {
        success: true,
        data: items,
        nextToken: data.nextToken
      }
    }
    return {
      success: false,
      error: res.data.errors
    }
  }
  catch (e) {
    return {
      success: false,
      error: e
    }
  }
}

const _listConversationsSortByEval = async (cityId, nextToken, sortDirection='DESC', isExternal=true) => {
  try {
    const res = await API.graphql({
      query: isExternal? findExternalConversationsSortByEvaluation: customFindInternalConversationsByEvaluation,
      variables: {
        cityId: cityId,
        sortDirection,
        nextToken
      },
      authMode: APP_AUTH_MODE
    })
    if (res.data[isExternal? 'findExternalConversationsSortByEvaluation': 'findInternalConversationsByEvaluation']) {
      const data = res.data[isExternal? 'findExternalConversationsSortByEvaluation': 'findInternalConversationsByEvaluation'];
      const items = data.items;
      for(const item of items) {
        if (item.evaluation) {
          item.evaluation = JSON.parse(item.evaluation);
        }
      }
      return {
        success: true,
        data: items,
        nextToken: data.nextToken
      }
    }
    return {
      success: false,
      error: res.data.errors
    }
  }
  catch (e) {
    return {
      success: false,
      error: e
    }
  }
}
const _listConversationsSortBySimilarity = async (cityId, nextToken, sortDirection='DESC', isExternal=true) => {
  try {
    const res = await API.graphql({
      query: isExternal? findExternalConversationsSortBySimilarity: customFindInternalConversationsBySimilarity,
      variables: {
        cityId: cityId,
        sortDirection,
        nextToken
      },
      authMode: APP_AUTH_MODE
    })
    if (res.data[isExternal? 'findExternalConversationsSortBySimilarity': 'findInternalConversationsBySimilarity']) {
      const data = res.data[isExternal? 'findExternalConversationsSortBySimilarity': 'findInternalConversationsBySimilarity'];
      const items = data.items;
      for(const item of items) {
        if (item.evaluation) {
          item.evaluation = JSON.parse(item.evaluation);
        }
      }
      return {
        success: true,
        data: items,
        nextToken: data.nextToken
      }
    }
    return {
      success: false,
      error: res.data.errors
    }
  }
  catch (e) {
    return {
      success: false,
      error: e
    }
  }
}

const listExternalConversationsSortByDate = async (cityId, sortDirection, nextToken) => {
  return await _listConversationsSortByDate(cityId, nextToken, sortDirection, true);
}

const listInternalConversationsSortByDate = async (cityId, sortDirection, nextToken) => {
  return await _listConversationsSortByDate(cityId, nextToken, sortDirection, false)
}
const listExternalConversationsSortBySimilarity = async (cityId, sortDirection, nextToken) => {
  return await _listConversationsSortBySimilarity(cityId, nextToken, sortDirection, true);
}

const listInternalConversationsSortBySimilarity = async (cityId, sortDirection, nextToken) => {
  return await _listConversationsSortBySimilarity(cityId, nextToken, sortDirection, false)
}

const listExternalConversationsSortByEval = async (cityId, sortDirection, nextToken) => {
  return await _listConversationsSortByEval(cityId, nextToken, sortDirection, true);
}

const listInternalConversationsSortByEval = async (cityId, sortDirection, nextToken) => {
  return await _listConversationsSortByEval(cityId, nextToken, sortDirection, false)
}

const _listConversationMessages = async (conversationId, nextToken, isExternal=true) => {
  try {
    const res = await API.graphql({
      query: isExternal? listExternalMessages : listInternalMessages,
      variables: {
        conversationId: conversationId,
        sortDirection: 'DESC',
        nextToken
      },
      authMode: APP_AUTH_MODE
    })
    const data = res.data[isExternal? 'listExternalMessages': 'listInternalMessages'];
    if (data) {
      data.items.reverse();
      return {
        success: true,
        data: data.items,
        nextToken: data.nextToken
      }
    }
    return {
      success: false,
      error: res.data.errors
    }
  }
  catch (e) {
    return {
      success: false,
      error: e
    }
  }
}

const listExternalConversationMessages = async (conversationId, nextToken=null) => {
  return _listConversationMessages(conversationId, nextToken, true);
}

const listInternalConversationMessages = async (conversationId, nextToken=null) => {
  return _listConversationMessages(conversationId, nextToken, false);
}

const adminDeleteConversation = async(ownerId, createdAt, isInternal) => {
  try {
    const mutation = isInternal ? deleteInternalConversation : deleteExternalConversation;
    const res = await API.graphql({
      query: mutation,
      variables: {
        input: {
          ownerId: ownerId,
          createdAt: createdAt
        }
      },
      authMode: APP_AUTH_MODE
    });

    const mutationResult = res.data[isInternal ? 'deleteInternalConversation' : 'deleteExternalConversation'];
    const success = Boolean(mutationResult);

    return {
      success,
      data: success ? res : null,
      nextToken: success ? mutationResult.nextToken : null,
      error: success ? null : res.errors || 'Deletion unsuccessful'
    };
  } catch (err) {
    return {
      success: false,
      error: err
    };
  }
}

const adminDeleteConversationMessage = async (conversationId, createdAt, isInternal) => {
  try {
    const mutation = isInternal ? deleteInternalMessage : deleteExternalMessage;
    const res = await API.graphql({
      query: mutation,
      variables: {
        input: {
          conversationId: conversationId,
          createdAt: createdAt
        }
      },
      authMode: APP_AUTH_MODE
    });

    const mutationResult = res.data[isInternal ? 'deleteInternalMessage' : 'deleteExternalMessage'];
    const success = Boolean(mutationResult);

    return {
      success,
      data: success ? res : null,
      nextToken: success ? mutationResult.nextToken : null,
      error: success ? null : res.errors || 'Deletion unsuccessful'
    };
  } catch (err) {
    return {
      sucess: false,
      error: err
    }
  }
}

const fixChatbotAnswer =  async (governmentId, conversationId, createdAt, faq, question, answer, url, isExternal=true) => {
  try {
    const srcMsgKey = JSON.stringify({
      conversationId,
      createdAt
    })
    let faqRes = null;
    if (faq) {
      faq.name = question;
      faq.answer = answer;
      faq.url = url;
      faqRes = await updateResource(faq, {name: question, answer, url});
    }
    else {
      faqRes = await createFaq(governmentId, question, answer, url, srcMsgKey, isExternal);
    }
    if (faqRes?.error) {
      return faqRes;
    }
    const newFaq = faqRes.data;
    let msg;
    if (newFaq?.createdAt) {
      const msgRes = await API.graphql({
        query: isExternal? updateExternalMessage: updateInternalMessage,
        variables: {
          input: {
            conversationId,
            createdAt: createdAt,
            fixAnswerCreatedAt: newFaq.createdAt
          }
        },
        authMode: APP_AUTH_MODE
      });
      if (msgRes.errors)  {
        return {
          success: false,
          error: msgRes.errors
        }
      }
      msg = msgRes.data[isExternal ? 'updateExternalMessage' : 'updateInternalMessage'];

    }
    return {
      success: true,
      data: msg
    }
  }
  catch (e) {
    return {
      success: false,
      error: e
    }
  }
}

const listMessagesByMonth = async (governmentId, month, isExternal) => {
  try {
    const query = isExternal? findExternalConversationsByCityId: findInternalConversationsByCityId;
    const property = isExternal? 'findExternalConversationsByCityId': 'findInternalConversationsByCityId';
    let nextToken = null;
    let params = {
      cityId: governmentId,
      createdAt: {
        beginsWith: month
      }
    }
    const conversations = [];
    // eslint-disable-next-line no-constant-condition
    while(true) {
      params.nextToken = nextToken;
      const conversationRes = await API.graphql({
        query,
        variables: params,
        authMode: APP_AUTH_MODE
      });
      conversations.push(...conversationRes.data[property].items);
      nextToken = conversationRes.data[property].nextToken;
      if (!nextToken) break;
    }
    for(const conversation of conversations) {
      conversation.messages = [];
      nextToken = null;
      const msgQuery = isExternal? listExternalMessages: listInternalMessages;
      const msgProperty = isExternal? 'listExternalMessages': 'listInternalMessages';
      const msgParams = {
        conversationId: conversation.id,
        sortDirection: 'ASC'
      }
      // eslint-disable-next-line no-constant-condition
      while (true) {
        msgParams.nextToken = nextToken;
        const msgRes = await API.graphql({
          query: msgQuery,
          variables: msgParams,
          authMode: APP_AUTH_MODE
        });
        conversation.messages.push(...msgRes.data[msgProperty].items);
        nextToken = msgRes.data[msgProperty].nextToken;
        if (!nextToken) break;
      }
    }
    return {
      success: true,
      data: conversations
    }
  }
  catch (e) {
    return {
      success: false,
      error: e
    }
  }
}

const listMessagesByMonth2 = async (governmentId, month) => {
  try {
    const messages = [];
    let nextToken = null;
    const msgParams = {
      governmentId,
      createdAt: {
        beginsWith: month
      },
      sortDirection: 'DESC'
    }
    // download all messages
    // eslint-disable-next-line no-constant-condition
    while (true) {
      msgParams.nextToken = nextToken;
      const msgRes = await API.graphql({
        query: findExternalMessagesByGovernment,
        variables: msgParams,
        authMode: APP_AUTH_MODE
      });
      messages.push(...msgRes.data.findExternalMessagesByGovernment.items);
      nextToken = msgRes.data.findExternalMessagesByGovernment.nextToken;
      if (!nextToken) break;
    }
    // download all conversations
    const uniqueConversations = [];
    for(const message of messages) {
      const conversation = uniqueConversations.find(c => c.id === message.conversationId);
      if (!conversation) {
        uniqueConversations.push({
          id: message.conversationId,
          earliestMessageAt: message.createdAt,
        });
      }
      else {
        conversation.earliestMessageAt = conversation.earliestMessageAt < message.createdAt ? conversation.earliestMessageAt : message.createdAt;
      }
    }
    let latestConversationCreatedAt = uniqueConversations[0].earliestMessageAt;
    for (let i=1; i<uniqueConversations.length; i++) {
      latestConversationCreatedAt = latestConversationCreatedAt < uniqueConversations[i].earliestMessageAt ? uniqueConversations[i].earliestMessageAt : latestConversationCreatedAt;
    }
    nextToken = null;
    const conversations = [];
    const convParams = {
      cityId: governmentId,
      createdAt: {
        le: latestConversationCreatedAt
      },
      sortDirection: 'DESC'
    }
    const uniqueConversationIds = uniqueConversations.map(c => c.id);
    // eslint-disable-next-line no-constant-condition
    while (true) {
      convParams.nextToken = nextToken;
      const convRes = await API.graphql({
        query: findExternalConversationsByCityId,
        variables: convParams,
        authMode: APP_AUTH_MODE
      });
      nextToken = convRes.data.findExternalConversationsByCityId.nextToken;
      for(const conversation of convRes.data.findExternalConversationsByCityId.items) {
        if (uniqueConversationIds.includes(conversation.id)) {
          conversations.push(conversation);
        }
      }
      if (!nextToken || conversations.length === uniqueConversationIds.length) break;
    }
    for(const conversation of conversations) {
      for (const message of messages) {
        if (message.conversationId === conversation.id) {
          message.conversationName = conversation.name;
        }
      }
    }
    return {
      success: true,
      data: messages
    }
  }
  catch (e) {
    return {
      success: false,
      error: e
    }
  }
}

const updateMessageTag = async (conversationId, createdAt, tag) => {
  try {
    const res = await API.graphql({
      query: updateExternalMessage,
      variables: {
        input: {
          conversationId,
          createdAt,
          tag
        }
      },
      authMode: APP_AUTH_MODE
    });
    return {
      success: true,
      data: res.data.updateExternalMessage
    }
  }
  catch (e) {
    return {
      success: false,
      error: e
    }
  }
}

const updateMessage = async (conversationId, createdAt, newProps) => {
  try {
    const params = {
      query: updateExternalMessage,
      variables: {
        input: {
          conversationId,
          createdAt,
          ...newProps
        }
      },
      authMode: APP_AUTH_MODE
    };
    const res = await API.graphql(params);
    return {
      success: true,
      data: res.data.updateExternalMessage
    }
  }
  catch (e) {
    return {
      success: false,
      error: e
    }
  }
}

const listExternalMessagesByGovernment = async (governmentId, nextToken, searchOptions = {}) => {
  try {
    let data = [];
    while (data.length < 5 * NUM_ITEMS_PER_PAGE) {

      const variables = {
        governmentId,
        nextToken,
        sortDirection: "DESC",
      };

      if (searchOptions.tag || searchOptions.like !== undefined || searchOptions.keyword) {
        variables.filter = {};

        if (searchOptions.tag && searchOptions.tag !== "部署タグ選択") {
          variables.filter = {
            tag: { eq: searchOptions.tag },
          };
        }

        if (searchOptions.like !== undefined && searchOptions.like !== "評価を選択") {
          if (searchOptions.like === null) {
            variables.filter = {
              ...variables.filter,
              like: { attributeExists: false },
            };
          } else {
            variables.filter = {
              ...variables.filter,
              like: { eq: searchOptions.like },
            };
          }
        }

        if (searchOptions.keyword) {
          variables.filter = {
            ...variables.filter,
            or: [
              { answer: { contains: searchOptions.keyword } },
              { tag: { contains: searchOptions.keyword } },
              { question: { contains: searchOptions.keyword } },
            ],
          };
        }

      }
      const res = await API.graphql({
        query: findExternalMessagesByGovernment,
        variables: variables,
        authMode: APP_AUTH_MODE,
      });
      nextToken = res.data.findExternalMessagesByGovernment.nextToken;
      data = data.concat(res.data.findExternalMessagesByGovernment.items);
      if (!nextToken) break;
    }
    return {
      success: true,
      data: data,
    };
  } catch (e) {
    return {
      success: false,
      error: e,
    };
  }
};
export {
  getExternalMessage,
  getInternalMessage,
  listExternalConversationsSortByDate,
  listInternalConversationsSortByDate,
  listExternalConversationsSortByEval,
  listInternalConversationsSortBySimilarity,
  listExternalConversationsSortBySimilarity,
  listInternalConversationsSortByEval,
  listExternalConversationMessages,
  listInternalConversationMessages,
  adminDeleteConversation,
  adminDeleteConversationMessage,
  fixChatbotAnswer,
  listMessagesByMonth,
  listMessagesByMonth2,
  updateMessageTag,
  updateMessage,
  listExternalMessagesByGovernment
}
