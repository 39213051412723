/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAnalysis = /* GraphQL */ `
  mutation CreateAnalysis(
    $condition: ModelAnalysisConditionInput
    $input: CreateAnalysisInput!
  ) {
    createAnalysis(condition: $condition, input: $input) {
      createdAt
      external {
        totalRegisteredUsers
        __typename
      }
      externalMessage
      externalUserCount
      governmentId
      internal {
        totalRegisteredUsers
        __typename
      }
      internalMessage
      internalUserCount
      japanDay
      updatedAt
      __typename
    }
  }
`;
export const createCatalog = /* GraphQL */ `
  mutation CreateCatalog(
    $condition: ModelCatalogConditionInput
    $input: CreateCatalogInput!
  ) {
    createCatalog(condition: $condition, input: $input) {
      callCenterUrl
      categoryCode
      categoryId
      code
      createdAt
      excludeWordList
      governmentId
      id
      isActive
      isDisplayed
      jsonSchema
      llmVersersion
      maxTokens
      maxUrls
      noAnswerReply
      openSearchHost
      osBaseFilter
      osBaseIndex
      osBaseK
      promptAssistantContent
      promptDefaultTemplate
      promptTemplate
      queryGeneratorTemplate
      similarityBaseMinRate
      temperature
      title
      type
      uiSchema
      updatedAt
      __typename
    }
  }
`;
export const createCity = /* GraphQL */ `
  mutation CreateCity(
    $condition: ModelCityConditionInput
    $input: CreateCityInput!
  ) {
    createCity(condition: $condition, input: $input) {
      callcenterUrl
      cityId
      createdAt
      excludeUrlWord
      name
      noideaText
      pinconeIndex
      pineconeFilter
      pineconeSearchK
      prefix
      promptQueryUrl
      promptSearchWord
      promptTemplate
      updatedAt
      __typename
    }
  }
`;
export const createExternalConversation = /* GraphQL */ `
  mutation CreateExternalConversation(
    $condition: ModelExternalConversationConditionInput
    $input: CreateExternalConversationInput!
  ) {
    createExternalConversation(condition: $condition, input: $input) {
      cityId
      createdAt
      evaluation
      id
      name
      ownerId
      similarity
      updatedAt
      __typename
    }
  }
`;
export const createExternalMessage = /* GraphQL */ `
  mutation CreateExternalMessage(
    $condition: ModelExternalMessageConditionInput
    $input: CreateExternalMessageInput!
  ) {
    createExternalMessage(condition: $condition, input: $input) {
      answer
      conversationId
      createdAt
      credibility
      credibilityReason
      fixAnswerCreatedAt
      governmentId
      id
      like
      question
      similarity
      tag
      updatedAt
      url
      __typename
    }
  }
`;
export const createExternalResource = /* GraphQL */ `
  mutation CreateExternalResource(
    $condition: ModelExternalResourceConditionInput
    $input: CreateExternalResourceInput!
  ) {
    createExternalResource(condition: $condition, input: $input) {
      answer
      createdAt
      execution
      executionDetail
      governmentId
      id
      name
      process
      processedAt
      rejectReason
      srcMsgKey
      status
      type
      updatedAt
      url
      __typename
    }
  }
`;
export const createGovernment = /* GraphQL */ `
  mutation CreateGovernment(
    $condition: ModelGovernmentConditionInput
    $input: CreateGovernmentInput!
  ) {
    createGovernment(condition: $condition, input: $input) {
      createdAt
      departments
      externalMsgCount
      governmentId
      governmentName
      internalMsgCount
      ipWhitelist
      maxExternalMsgPerGovernment
      maxExternalMsgPerUser
      maxInternalMsgPerGovernment
      maxInternalMsgPerUser
      prefecture
      searchConditions {
        excludeExternalUrlWord
        excludeExternalWordList
        excludeInternalUrlWord
        excludeInternalWordList
        externalCallCenterUrl
        externalMaxUrls
        externalNoAnswerReply
        generativeDocumentTemplate
        governmentPrefix
        internalCallCenterUrl
        internalMaxUrls
        internalNoAnswerReply
        openSearchHost
        osExternalBaseFilter
        osExternalBaseIndex
        osExternalBaseK
        osExternalFaqFilter
        osExternalFaqIndex
        osExternalFaqK
        osInternalBaseFilter
        osInternalBaseIndex
        osInternalBaseK
        osInternalFaqFilter
        osInternalFaqIndex
        osInternalFaqK
        promptTemplate
        searchExternalGoogleUrlList
        similarityExternalBaseMinRate
        similarityExternalFaqMinRate
        similarityInternalBaseMinRate
        similarityInternalFaqMinRate
        __typename
      }
      status
      updatedAt
      __typename
    }
  }
`;
export const createInternalConversation = /* GraphQL */ `
  mutation CreateInternalConversation(
    $condition: ModelInternalConversationConditionInput
    $input: CreateInternalConversationInput!
  ) {
    createInternalConversation(condition: $condition, input: $input) {
      catalogCreatedAt
      catalogType
      cityId
      createdAt
      evaluation
      id
      isDeleted
      jsonData
      name
      owner {
        createdAt
        email
        enabled
        governmentId
        groups
        id
        isDeleted
        isVerified
        lastMessageAt
        messageCount
        status
        updatedAt
        username
        __typename
      }
      ownerId
      similarity
      updatedAt
      __typename
    }
  }
`;
export const createInternalMessage = /* GraphQL */ `
  mutation CreateInternalMessage(
    $condition: ModelInternalMessageConditionInput
    $input: CreateInternalMessageInput!
  ) {
    createInternalMessage(condition: $condition, input: $input) {
      conversationId
      createdAt
      fixAnswerCreatedAt
      id
      like
      role
      similarity
      text
      updatedAt
      url
      __typename
    }
  }
`;
export const createInternalResource = /* GraphQL */ `
  mutation CreateInternalResource(
    $condition: ModelInternalResourceConditionInput
    $input: CreateInternalResourceInput!
  ) {
    createInternalResource(condition: $condition, input: $input) {
      answer
      categoryId
      createdAt
      execution
      executionDetail
      governmentId
      id
      name
      process
      rejectReason
      srcMsgKey
      status
      type
      updatedAt
      url
      __typename
    }
  }
`;
export const createLineBot = /* GraphQL */ `
  mutation CreateLineBot(
    $condition: ModelLineBotConditionInput
    $input: CreateLineBotInput!
  ) {
    createLineBot(condition: $condition, input: $input) {
      botUserId
      cityId
      createdAt
      lineChannelAccessToken
      lineChannelSecret
      updatedAt
      __typename
    }
  }
`;
export const createLineMessages = /* GraphQL */ `
  mutation CreateLineMessages(
    $condition: ModelLineMessagesConditionInput
    $input: CreateLineMessagesInput!
  ) {
    createLineMessages(condition: $condition, input: $input) {
      content
      createdAt
      id
      lineUserId
      role
      updatedAt
      __typename
    }
  }
`;
export const createMonthlyAnalysis = /* GraphQL */ `
  mutation CreateMonthlyAnalysis(
    $condition: ModelMonthlyAnalysisConditionInput
    $input: CreateMonthlyAnalysisInput!
  ) {
    createMonthlyAnalysis(condition: $condition, input: $input) {
      createdAt
      externalBadEvalCount
      externalConversationCount
      externalDislikeCount
      externalGoodEvalCount
      externalLikeCount
      externalUserCount
      governmentId
      internalBadEvalCount
      internalConversationCount
      internalDislikeCount
      internalGoodEvalCount
      internalLikeCount
      internalUserCount
      japanMonth
      updatedAt
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $condition: ModelUserConditionInput
    $input: CreateUserInput!
  ) {
    createUser(condition: $condition, input: $input) {
      createdAt
      email
      enabled
      governmentId
      groups
      id
      isDeleted
      isVerified
      lastMessageAt
      messageCount
      status
      updatedAt
      username
      __typename
    }
  }
`;
export const createWebPage = /* GraphQL */ `
  mutation CreateWebPage(
    $condition: ModelWebPageConditionInput
    $input: CreateWebPageInput!
  ) {
    createWebPage(condition: $condition, input: $input) {
      baseUrlId
      createdAt
      detailUrl
      execution
      executionDetail
      id
      rejectReason
      status
      type
      updatedAt
      __typename
    }
  }
`;
export const deleteAnalysis = /* GraphQL */ `
  mutation DeleteAnalysis(
    $condition: ModelAnalysisConditionInput
    $input: DeleteAnalysisInput!
  ) {
    deleteAnalysis(condition: $condition, input: $input) {
      createdAt
      external {
        totalRegisteredUsers
        __typename
      }
      externalMessage
      externalUserCount
      governmentId
      internal {
        totalRegisteredUsers
        __typename
      }
      internalMessage
      internalUserCount
      japanDay
      updatedAt
      __typename
    }
  }
`;
export const deleteCatalog = /* GraphQL */ `
  mutation DeleteCatalog(
    $condition: ModelCatalogConditionInput
    $input: DeleteCatalogInput!
  ) {
    deleteCatalog(condition: $condition, input: $input) {
      callCenterUrl
      categoryCode
      categoryId
      code
      createdAt
      excludeWordList
      governmentId
      id
      isActive
      isDisplayed
      jsonSchema
      llmVersersion
      maxTokens
      maxUrls
      noAnswerReply
      openSearchHost
      osBaseFilter
      osBaseIndex
      osBaseK
      promptAssistantContent
      promptDefaultTemplate
      promptTemplate
      queryGeneratorTemplate
      similarityBaseMinRate
      temperature
      title
      type
      uiSchema
      updatedAt
      __typename
    }
  }
`;
export const deleteCity = /* GraphQL */ `
  mutation DeleteCity(
    $condition: ModelCityConditionInput
    $input: DeleteCityInput!
  ) {
    deleteCity(condition: $condition, input: $input) {
      callcenterUrl
      cityId
      createdAt
      excludeUrlWord
      name
      noideaText
      pinconeIndex
      pineconeFilter
      pineconeSearchK
      prefix
      promptQueryUrl
      promptSearchWord
      promptTemplate
      updatedAt
      __typename
    }
  }
`;
export const deleteExternalConversation = /* GraphQL */ `
  mutation DeleteExternalConversation(
    $condition: ModelExternalConversationConditionInput
    $input: DeleteExternalConversationInput!
  ) {
    deleteExternalConversation(condition: $condition, input: $input) {
      cityId
      createdAt
      evaluation
      id
      name
      ownerId
      similarity
      updatedAt
      __typename
    }
  }
`;
export const deleteExternalMessage = /* GraphQL */ `
  mutation DeleteExternalMessage(
    $condition: ModelExternalMessageConditionInput
    $input: DeleteExternalMessageInput!
  ) {
    deleteExternalMessage(condition: $condition, input: $input) {
      answer
      conversationId
      createdAt
      credibility
      credibilityReason
      fixAnswerCreatedAt
      governmentId
      id
      like
      question
      similarity
      tag
      updatedAt
      url
      __typename
    }
  }
`;
export const deleteExternalResource = /* GraphQL */ `
  mutation DeleteExternalResource(
    $condition: ModelExternalResourceConditionInput
    $input: DeleteExternalResourceInput!
  ) {
    deleteExternalResource(condition: $condition, input: $input) {
      answer
      createdAt
      execution
      executionDetail
      governmentId
      id
      name
      process
      processedAt
      rejectReason
      srcMsgKey
      status
      type
      updatedAt
      url
      __typename
    }
  }
`;
export const deleteGovernment = /* GraphQL */ `
  mutation DeleteGovernment(
    $condition: ModelGovernmentConditionInput
    $input: DeleteGovernmentInput!
  ) {
    deleteGovernment(condition: $condition, input: $input) {
      createdAt
      departments
      externalMsgCount
      governmentId
      governmentName
      internalMsgCount
      ipWhitelist
      maxExternalMsgPerGovernment
      maxExternalMsgPerUser
      maxInternalMsgPerGovernment
      maxInternalMsgPerUser
      prefecture
      searchConditions {
        excludeExternalUrlWord
        excludeExternalWordList
        excludeInternalUrlWord
        excludeInternalWordList
        externalCallCenterUrl
        externalMaxUrls
        externalNoAnswerReply
        generativeDocumentTemplate
        governmentPrefix
        internalCallCenterUrl
        internalMaxUrls
        internalNoAnswerReply
        openSearchHost
        osExternalBaseFilter
        osExternalBaseIndex
        osExternalBaseK
        osExternalFaqFilter
        osExternalFaqIndex
        osExternalFaqK
        osInternalBaseFilter
        osInternalBaseIndex
        osInternalBaseK
        osInternalFaqFilter
        osInternalFaqIndex
        osInternalFaqK
        promptTemplate
        searchExternalGoogleUrlList
        similarityExternalBaseMinRate
        similarityExternalFaqMinRate
        similarityInternalBaseMinRate
        similarityInternalFaqMinRate
        __typename
      }
      status
      updatedAt
      __typename
    }
  }
`;
export const deleteInternalConversation = /* GraphQL */ `
  mutation DeleteInternalConversation(
    $condition: ModelInternalConversationConditionInput
    $input: DeleteInternalConversationInput!
  ) {
    deleteInternalConversation(condition: $condition, input: $input) {
      catalogCreatedAt
      catalogType
      cityId
      createdAt
      evaluation
      id
      isDeleted
      jsonData
      name
      owner {
        createdAt
        email
        enabled
        governmentId
        groups
        id
        isDeleted
        isVerified
        lastMessageAt
        messageCount
        status
        updatedAt
        username
        __typename
      }
      ownerId
      similarity
      updatedAt
      __typename
    }
  }
`;
export const deleteInternalMessage = /* GraphQL */ `
  mutation DeleteInternalMessage(
    $condition: ModelInternalMessageConditionInput
    $input: DeleteInternalMessageInput!
  ) {
    deleteInternalMessage(condition: $condition, input: $input) {
      conversationId
      createdAt
      fixAnswerCreatedAt
      id
      like
      role
      similarity
      text
      updatedAt
      url
      __typename
    }
  }
`;
export const deleteInternalResource = /* GraphQL */ `
  mutation DeleteInternalResource(
    $condition: ModelInternalResourceConditionInput
    $input: DeleteInternalResourceInput!
  ) {
    deleteInternalResource(condition: $condition, input: $input) {
      answer
      categoryId
      createdAt
      execution
      executionDetail
      governmentId
      id
      name
      process
      rejectReason
      srcMsgKey
      status
      type
      updatedAt
      url
      __typename
    }
  }
`;
export const deleteLineBot = /* GraphQL */ `
  mutation DeleteLineBot(
    $condition: ModelLineBotConditionInput
    $input: DeleteLineBotInput!
  ) {
    deleteLineBot(condition: $condition, input: $input) {
      botUserId
      cityId
      createdAt
      lineChannelAccessToken
      lineChannelSecret
      updatedAt
      __typename
    }
  }
`;
export const deleteLineMessages = /* GraphQL */ `
  mutation DeleteLineMessages(
    $condition: ModelLineMessagesConditionInput
    $input: DeleteLineMessagesInput!
  ) {
    deleteLineMessages(condition: $condition, input: $input) {
      content
      createdAt
      id
      lineUserId
      role
      updatedAt
      __typename
    }
  }
`;
export const deleteMonthlyAnalysis = /* GraphQL */ `
  mutation DeleteMonthlyAnalysis(
    $condition: ModelMonthlyAnalysisConditionInput
    $input: DeleteMonthlyAnalysisInput!
  ) {
    deleteMonthlyAnalysis(condition: $condition, input: $input) {
      createdAt
      externalBadEvalCount
      externalConversationCount
      externalDislikeCount
      externalGoodEvalCount
      externalLikeCount
      externalUserCount
      governmentId
      internalBadEvalCount
      internalConversationCount
      internalDislikeCount
      internalGoodEvalCount
      internalLikeCount
      internalUserCount
      japanMonth
      updatedAt
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $condition: ModelUserConditionInput
    $input: DeleteUserInput!
  ) {
    deleteUser(condition: $condition, input: $input) {
      createdAt
      email
      enabled
      governmentId
      groups
      id
      isDeleted
      isVerified
      lastMessageAt
      messageCount
      status
      updatedAt
      username
      __typename
    }
  }
`;
export const deleteWebPage = /* GraphQL */ `
  mutation DeleteWebPage(
    $condition: ModelWebPageConditionInput
    $input: DeleteWebPageInput!
  ) {
    deleteWebPage(condition: $condition, input: $input) {
      baseUrlId
      createdAt
      detailUrl
      execution
      executionDetail
      id
      rejectReason
      status
      type
      updatedAt
      __typename
    }
  }
`;
export const evaluateExternalConversation = /* GraphQL */ `
  mutation EvaluateExternalConversation($input: EvaluateConversationInput!) {
    evaluateExternalConversation(input: $input) {
      data {
        cityId
        createdAt
        evaluation
        id
        name
        ownerId
        similarity
        updatedAt
        __typename
      }
      error
      success
      __typename
    }
  }
`;
export const evaluateInternalConversation = /* GraphQL */ `
  mutation EvaluateInternalConversation($input: EvaluateConversationInput!) {
    evaluateInternalConversation(input: $input) {
      data {
        catalogCreatedAt
        catalogType
        cityId
        createdAt
        evaluation
        id
        isDeleted
        jsonData
        name
        ownerId
        similarity
        updatedAt
        __typename
      }
      error
      success
      __typename
    }
  }
`;
export const likeExternalMessage = /* GraphQL */ `
  mutation LikeExternalMessage($input: LikeMessageInput!) {
    likeExternalMessage(input: $input) {
      data {
        answer
        conversationId
        createdAt
        credibility
        credibilityReason
        fixAnswerCreatedAt
        governmentId
        id
        like
        question
        similarity
        tag
        updatedAt
        url
        __typename
      }
      error
      success
      __typename
    }
  }
`;
export const likeInternalMessage = /* GraphQL */ `
  mutation LikeInternalMessage($input: LikeMessageInput!) {
    likeInternalMessage(input: $input) {
      data {
        conversationId
        createdAt
        fixAnswerCreatedAt
        id
        like
        role
        similarity
        text
        updatedAt
        url
        __typename
      }
      error
      success
      __typename
    }
  }
`;
export const processExternalManualWebpage = /* GraphQL */ `
  mutation ProcessExternalManualWebpage($input: ProcessManualWebpageInput!) {
    processExternalManualWebpage(input: $input) {
      data {
        baseUrlId
        createdAt
        detailUrl
        execution
        executionDetail
        id
        rejectReason
        status
        type
        updatedAt
        __typename
      }
      error
      success
      __typename
    }
  }
`;
export const processExternalResource = /* GraphQL */ `
  mutation ProcessExternalResource($input: ProcessResourceInput!) {
    processExternalResource(input: $input) {
      data {
        answer
        createdAt
        execution
        executionDetail
        governmentId
        id
        name
        process
        processedAt
        rejectReason
        srcMsgKey
        status
        type
        updatedAt
        url
        __typename
      }
      error
      success
      __typename
    }
  }
`;
export const processInternalManualWebpage = /* GraphQL */ `
  mutation ProcessInternalManualWebpage($input: ProcessManualWebpageInput!) {
    processInternalManualWebpage(input: $input) {
      data {
        baseUrlId
        createdAt
        detailUrl
        execution
        executionDetail
        id
        rejectReason
        status
        type
        updatedAt
        __typename
      }
      error
      success
      __typename
    }
  }
`;
export const processInternalResource = /* GraphQL */ `
  mutation ProcessInternalResource($input: ProcessResourceInput!) {
    processInternalResource(input: $input) {
      data {
        answer
        categoryId
        createdAt
        execution
        executionDetail
        governmentId
        id
        name
        process
        rejectReason
        srcMsgKey
        status
        type
        updatedAt
        url
        __typename
      }
      error
      success
      __typename
    }
  }
`;
export const sendCatalogMessage = /* GraphQL */ `
  mutation SendCatalogMessage($input: SendCatalogMessageInput!) {
    sendCatalogMessage(input: $input) {
      data {
        conversationId
        createdAt
        fixAnswerCreatedAt
        id
        like
        role
        similarity
        text
        updatedAt
        url
        __typename
      }
      error
      success
      __typename
    }
  }
`;
export const sendExternalMessage = /* GraphQL */ `
  mutation SendExternalMessage($input: SendExternalMessagInput!) {
    sendExternalMessage(input: $input) {
      data {
        answer
        conversationId
        createdAt
        credibility
        credibilityReason
        fixAnswerCreatedAt
        governmentId
        id
        like
        question
        similarity
        tag
        updatedAt
        url
        __typename
      }
      error
      ragData
      success
      __typename
    }
  }
`;
export const sendInternalMessage = /* GraphQL */ `
  mutation SendInternalMessage($input: SendInternalMessageInput!) {
    sendInternalMessage(input: $input) {
      data {
        conversationId
        createdAt
        fixAnswerCreatedAt
        id
        like
        role
        similarity
        text
        updatedAt
        url
        __typename
      }
      error
      success
      __typename
    }
  }
`;
export const updateAnalysis = /* GraphQL */ `
  mutation UpdateAnalysis(
    $condition: ModelAnalysisConditionInput
    $input: UpdateAnalysisInput!
  ) {
    updateAnalysis(condition: $condition, input: $input) {
      createdAt
      external {
        totalRegisteredUsers
        __typename
      }
      externalMessage
      externalUserCount
      governmentId
      internal {
        totalRegisteredUsers
        __typename
      }
      internalMessage
      internalUserCount
      japanDay
      updatedAt
      __typename
    }
  }
`;
export const updateCatalog = /* GraphQL */ `
  mutation UpdateCatalog(
    $condition: ModelCatalogConditionInput
    $input: UpdateCatalogInput!
  ) {
    updateCatalog(condition: $condition, input: $input) {
      callCenterUrl
      categoryCode
      categoryId
      code
      createdAt
      excludeWordList
      governmentId
      id
      isActive
      isDisplayed
      jsonSchema
      llmVersersion
      maxTokens
      maxUrls
      noAnswerReply
      openSearchHost
      osBaseFilter
      osBaseIndex
      osBaseK
      promptAssistantContent
      promptDefaultTemplate
      promptTemplate
      queryGeneratorTemplate
      similarityBaseMinRate
      temperature
      title
      type
      uiSchema
      updatedAt
      __typename
    }
  }
`;
export const updateCity = /* GraphQL */ `
  mutation UpdateCity(
    $condition: ModelCityConditionInput
    $input: UpdateCityInput!
  ) {
    updateCity(condition: $condition, input: $input) {
      callcenterUrl
      cityId
      createdAt
      excludeUrlWord
      name
      noideaText
      pinconeIndex
      pineconeFilter
      pineconeSearchK
      prefix
      promptQueryUrl
      promptSearchWord
      promptTemplate
      updatedAt
      __typename
    }
  }
`;
export const updateExternalConversation = /* GraphQL */ `
  mutation UpdateExternalConversation(
    $condition: ModelExternalConversationConditionInput
    $input: UpdateExternalConversationInput!
  ) {
    updateExternalConversation(condition: $condition, input: $input) {
      cityId
      createdAt
      evaluation
      id
      name
      ownerId
      similarity
      updatedAt
      __typename
    }
  }
`;
export const updateExternalMessage = /* GraphQL */ `
  mutation UpdateExternalMessage(
    $condition: ModelExternalMessageConditionInput
    $input: UpdateExternalMessageInput!
  ) {
    updateExternalMessage(condition: $condition, input: $input) {
      answer
      conversationId
      createdAt
      credibility
      credibilityReason
      fixAnswerCreatedAt
      governmentId
      id
      like
      question
      similarity
      tag
      updatedAt
      url
      __typename
    }
  }
`;
export const updateExternalResource = /* GraphQL */ `
  mutation UpdateExternalResource(
    $condition: ModelExternalResourceConditionInput
    $input: UpdateExternalResourceInput!
  ) {
    updateExternalResource(condition: $condition, input: $input) {
      answer
      createdAt
      execution
      executionDetail
      governmentId
      id
      name
      process
      processedAt
      rejectReason
      srcMsgKey
      status
      type
      updatedAt
      url
      __typename
    }
  }
`;
export const updateGovernment = /* GraphQL */ `
  mutation UpdateGovernment(
    $condition: ModelGovernmentConditionInput
    $input: UpdateGovernmentInput!
  ) {
    updateGovernment(condition: $condition, input: $input) {
      createdAt
      departments
      externalMsgCount
      governmentId
      governmentName
      internalMsgCount
      ipWhitelist
      maxExternalMsgPerGovernment
      maxExternalMsgPerUser
      maxInternalMsgPerGovernment
      maxInternalMsgPerUser
      prefecture
      searchConditions {
        excludeExternalUrlWord
        excludeExternalWordList
        excludeInternalUrlWord
        excludeInternalWordList
        externalCallCenterUrl
        externalMaxUrls
        externalNoAnswerReply
        generativeDocumentTemplate
        governmentPrefix
        internalCallCenterUrl
        internalMaxUrls
        internalNoAnswerReply
        openSearchHost
        osExternalBaseFilter
        osExternalBaseIndex
        osExternalBaseK
        osExternalFaqFilter
        osExternalFaqIndex
        osExternalFaqK
        osInternalBaseFilter
        osInternalBaseIndex
        osInternalBaseK
        osInternalFaqFilter
        osInternalFaqIndex
        osInternalFaqK
        promptTemplate
        searchExternalGoogleUrlList
        similarityExternalBaseMinRate
        similarityExternalFaqMinRate
        similarityInternalBaseMinRate
        similarityInternalFaqMinRate
        __typename
      }
      status
      updatedAt
      __typename
    }
  }
`;
export const updateInternalConversation = /* GraphQL */ `
  mutation UpdateInternalConversation(
    $condition: ModelInternalConversationConditionInput
    $input: UpdateInternalConversationInput!
  ) {
    updateInternalConversation(condition: $condition, input: $input) {
      catalogCreatedAt
      catalogType
      cityId
      createdAt
      evaluation
      id
      isDeleted
      jsonData
      name
      owner {
        createdAt
        email
        enabled
        governmentId
        groups
        id
        isDeleted
        isVerified
        lastMessageAt
        messageCount
        status
        updatedAt
        username
        __typename
      }
      ownerId
      similarity
      updatedAt
      __typename
    }
  }
`;
export const updateInternalMessage = /* GraphQL */ `
  mutation UpdateInternalMessage(
    $condition: ModelInternalMessageConditionInput
    $input: UpdateInternalMessageInput!
  ) {
    updateInternalMessage(condition: $condition, input: $input) {
      conversationId
      createdAt
      fixAnswerCreatedAt
      id
      like
      role
      similarity
      text
      updatedAt
      url
      __typename
    }
  }
`;
export const updateInternalResource = /* GraphQL */ `
  mutation UpdateInternalResource(
    $condition: ModelInternalResourceConditionInput
    $input: UpdateInternalResourceInput!
  ) {
    updateInternalResource(condition: $condition, input: $input) {
      answer
      categoryId
      createdAt
      execution
      executionDetail
      governmentId
      id
      name
      process
      rejectReason
      srcMsgKey
      status
      type
      updatedAt
      url
      __typename
    }
  }
`;
export const updateLineBot = /* GraphQL */ `
  mutation UpdateLineBot(
    $condition: ModelLineBotConditionInput
    $input: UpdateLineBotInput!
  ) {
    updateLineBot(condition: $condition, input: $input) {
      botUserId
      cityId
      createdAt
      lineChannelAccessToken
      lineChannelSecret
      updatedAt
      __typename
    }
  }
`;
export const updateLineMessages = /* GraphQL */ `
  mutation UpdateLineMessages(
    $condition: ModelLineMessagesConditionInput
    $input: UpdateLineMessagesInput!
  ) {
    updateLineMessages(condition: $condition, input: $input) {
      content
      createdAt
      id
      lineUserId
      role
      updatedAt
      __typename
    }
  }
`;
export const updateMonthlyAnalysis = /* GraphQL */ `
  mutation UpdateMonthlyAnalysis(
    $condition: ModelMonthlyAnalysisConditionInput
    $input: UpdateMonthlyAnalysisInput!
  ) {
    updateMonthlyAnalysis(condition: $condition, input: $input) {
      createdAt
      externalBadEvalCount
      externalConversationCount
      externalDislikeCount
      externalGoodEvalCount
      externalLikeCount
      externalUserCount
      governmentId
      internalBadEvalCount
      internalConversationCount
      internalDislikeCount
      internalGoodEvalCount
      internalLikeCount
      internalUserCount
      japanMonth
      updatedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $condition: ModelUserConditionInput
    $input: UpdateUserInput!
  ) {
    updateUser(condition: $condition, input: $input) {
      createdAt
      email
      enabled
      governmentId
      groups
      id
      isDeleted
      isVerified
      lastMessageAt
      messageCount
      status
      updatedAt
      username
      __typename
    }
  }
`;
export const updateWebPage = /* GraphQL */ `
  mutation UpdateWebPage(
    $condition: ModelWebPageConditionInput
    $input: UpdateWebPageInput!
  ) {
    updateWebPage(condition: $condition, input: $input) {
      baseUrlId
      createdAt
      detailUrl
      execution
      executionDetail
      id
      rejectReason
      status
      type
      updatedAt
      __typename
    }
  }
`;
