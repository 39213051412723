import React from "react";
import { Button, Modal, Container, Form } from 'react-bootstrap';
import { Label } from "reactstrap";
import PropTypes from "prop-types";
import "./FAQRegisterDialog.css";
import { BUTTON_LABELS } from "../../lang/ja";

const propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  faq: PropTypes.object,
  onRegister: PropTypes.func,
  editable:  PropTypes.bool
};
const defaultProps = {
  isOpen: false,
  onClose: ()=>{},
  faq: null,
  onRegister: () => {},
  editable: true
};

const FAQRegisterDialog = (props) => {
  /***** Variables and States *****/
  const { isOpen, onClose, faq, onRegister, editable } = props;
  const [question, setQuestion] = React.useState('');
  const [answer, setAnswer] = React.useState('');
  const [url, setUrl] = React.useState('');
  /***** Processing *****/
  React.useEffect(()=>{
    setQuestion(faq?.name);
    setAnswer(faq?.answer);
    setUrl(faq?.url);
  }, [faq])
  /***** Main Render *****/
  if (!isOpen) return null;
  return <div
    className="modal show"
    style={{ display: 'block', position: 'absolute' }}>
    <Modal.Dialog className="p-0">
      <Modal.Header closeButton onHide={onClose}>
        <Modal.Title className="">{!faq.governmentId? BUTTON_LABELS.BUTTON_REGISTER_NEW: '修正'}</Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          maxHeight: '256px',
          overflow: 'auto'
        }}>
        <Container
          style={{
            paddingBottom: '8px'
          }}
          className="d-flex align-items-center">
          <Label className="w-25 m-0">
            質問
          </Label>
          <Form.Control
            className="w-75"
            as='textarea'
            rows={3}
            value={question}
            disabled={!!faq.srcMsgKey}
            onChange={(e)=>{setQuestion(e.target.value)}} />
        </Container>
        {faq.aiAnswer && <Container
          className="d-flex align-items-center"
          style={{
            paddingBottom: '8px'
          }}>
          <Label className="w-25 m-0">
            AI回答
          </Label>
          <Form.Control
            className="w-75"
            as='textarea'
            rows={3}
            value={faq.aiAnswer}
            disabled={true}/>
        </Container>}
        <Container
          className="d-flex align-items-center"
          style={{
            paddingBottom: '8px'
          }}>
          <Label className="w-25 m-0">
            回答
          </Label>
          <Form.Control
            className="w-75"
            as='textarea'
            rows={3}
            value={answer}
            disabled={!editable}
            onChange={(e)=>{setAnswer(e.target.value)}} />
        </Container>
        <Container
          className="d-flex align-items-center"
          style={{
            paddingBottom: '8px'
          }}>
          <Label className="w-25 m-0">
            URL
          </Label>
          <Form.Control
            className="w-75"
            as='textarea'
            rows={3}
            value={url}
            disabled={!editable}
            onChange={(e)=>{setUrl(e.target.value)}} />
        </Container>
      </Modal.Body>

      <Modal.Footer className="d-flex justify-content-end">
        <Button
          className="btn-outline btn-wd mr-1"
          variant="default"
          onClick={onClose}>
          キャンセル
        </Button>
        <Button
          className="btn-outline btn-wd mr-1"
          variant="default"
          disabled={!editable}
          onClick={()=>{onRegister(question, answer, url)}}>
          登録
        </Button>
      </Modal.Footer>
    </Modal.Dialog>
  </div>
}

FAQRegisterDialog.propTypes = propTypes;
FAQRegisterDialog.defaultProps = defaultProps;

export default FAQRegisterDialog;
